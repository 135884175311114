@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-transform: none;
  color: #1f1f1f;
  background-color: #f3f3f3;
}

h1 {
  text-transform: none;
  line-height: 140%;
  font-size: 2.6rem;
  letter-spacing: 0mm;
}

h2 {
  text-transform: none;
  line-height: 140%;
  font-size: 2rem;
  letter-spacing: 0mm;
}

h3 {
  text-transform: none;
  line-height: 140%;
  font-size: 1.6rem;
  letter-spacing: 0mm;
}

h4 {
  text-transform: none;
  line-height: 150%;
  font-size: 1rem;
  letter-spacing: 0mm;
}

h5 {
  text-transform: none;
  line-height: 140%;
  letter-spacing: 0mm;
}

p {
  color: #1f1f1f;
  letter-spacing: 0mm;
  font-size: 1rem;
  font-weight: normal;
}

.p-lg {
  font-size: 1.13rem;
}

a {
  text-decoration: none;
  color: #074b89;
}

.s-text {
  font-size: 0.87rem;
}

.container-heigh {
  padding-top: 18vh;
}

.bg-grey {
  background: #f3f3f3;
}

.bg-yellow {
  background: #f5b640;
}

.font-black {
  color: #1f1f1f;
}

.bg-white {
  background: #fff;
}

.bg-blue {
  background: #D1EEF0;
}

.font-blue {
  color: #074b89;
}

.bg-dark-blue {
  background-color: #074b89;
}


.font-error {
  color: #e31452;
}

.font-yellow {
  color: #f79e1b;
}

.btn-close {
  width: 6px;
  height: 6px;
}

.btn-more:focus,
.btn-more.focus {
  color: #1f1f1f;
  background-color: #f79e1b !important;
}
.btn-more:hover {
  color: #1f1f1f;
  background-color: #f79e1b !important;
}

.btn-yellow {
  background: #f5b640;
  border-radius: 6px;
  color: #1f1f1f !important;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: none;
  padding: 12px 30px;
}


.btn-primary:disabled, .btn-primary.disabled {
  color: #1f1f1f;
  background-color: #aeaaaa;
}

.btn-yellow:hover {
  background-color: #f4a514 !important;
  border-color: none;
  color: #1f1f1f !important;
}

.btn-yellow:focus {
  background-color: #f4a514 !important;
  color: #1f1f1f !important;
}
.btn-error {
  background: #e31452;
  border-radius: 6px;
  color: #fff;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: none;
  padding: 12px 10px;
}

.btn-error:hover {
  background-color: #e31452 !important;
  border-color: none;
  color: #fff !important;
}

.btn-error:focus {
  background-color: #e31452 !important;
  color: #fff !important;
}

.btn-secondary-blue {
  background: #ffffff;
  border: 2px solid #074b89;
  color: #074b89 !important;
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 30px;
}

.btn-secondary-blue:hover {
  background-color: #ffffff !important;
  border: 2px solid #074b89;
  color: #074b89;
}
.btn-secondary-blue:focus {
  background-color: #ffffff !important;
  border: 2px solid #074b89;
  color: #074b89;
}

.btn-klarna {
  background: #ffb3c7;
  border-radius: 6px;
  color: #1f1f1f;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: none;
  padding: 10px 30px;
}

.btn-klarna:hover {
  background-color: #ffb3c7 !important;
  color: #1f1f1f !important;
}
.btn-klarna:focus {
  background-color: #ffb3c7 !important;
  color: #1f1f1f !important;
}

.btn-arrow {
  padding: 12px;
  background: none;
  border: none;
  outline: none;
  transition: none;
  box-shadow: none;
}

.btn-arrow:hover {
  background-color: #ffffff !important;
  border-color: #ffffff;
}

.btn-arrow:focus {
  background-color: #ffffff !important;
  border-color: #ffffff;
}

.btn-arrow:not(:disabled):not(.disabled):active {
  background: #ffffff;
  border-color: #ffffff;
}

.btn-back {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 1rem;
}

.btn-back:focus {
  background: none;
  color: #074b89 !important;
  box-shadow: none;
  border: none;
}

.btn-back:active {
  background: none;
  border: none;
  color: #074b89;
  box-shadow: none;
}
.btn-back:hover {
  background: none;
  border: none;
  color: #074b89;
}

.btn-delete {
  background: none;
  border: none;
  text-transform: none;
  box-shadow: none;
  font-size: 0.87rem;
  padding: 0;
}

.btn-delete:focus {
  background: none;
  color: #074b89 !important;
  box-shadow: none;
}

.btn-delete:active {
  background: none;
  border: none;
  color: #074b89;
  box-shadow: none;
}
.btn-delete:hover {
  background: none;
  border: none;
  color: #074b89;
}

.btn-registration-mail {
  width: 100%;
}

.error-pink {
  background: #e31452;
  color: #fff;
  width: 100%;
  border-radius: 6px;
  line-height: 140%;
}

.error-pink2 {
  background: #e31452;
  color: #fff;
  width: 100%;
  border-radius: 6px;
  line-height: 140%;
}

.kredu-logo {
  width: 93px;
  margin-left: 4%;
}

.navbar {
  text-transform: none;
  height: 70px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#navbarDashboard .navbar-collapse,
#navbarDashboardOnly .navbar-collapse {
  background: #ffffff;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
}

.check {
  margin-right: 12px;
  width: 1.3rem;
}

.checkList {
  margin-bottom: 14px;
  font-size: 1.13rem;
  line-height: 170%;
}

.form-box {
  max-width: 450px;
}
.form-box2 {
  max-width: 100%;
}

#faq .accordion-button {
  font-weight: 400 !important;
  border-top: 1px solid #d6d5d5 !important;
  background-color: #f3f3f3 !important;
}


.accordion-body {
  color: #757575;
}
.accordion-button::after {
  border-color: #074b89 !important;
}

.accordion-button:not(.collapsed) {
  color: #1f1f1f !important;
}
.accordion-button:focus {
  color: #1f1f1f !important;
  border-color: #074b89 !important;
}

.accordion-header:not(.collapsed) {
  color: #1f1f1f !important;
}
.accordion-header:focus {
  color: #1f1f1f !important;
}

@media only screen and (min-width: 3280px) {
  * {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-collapse {
    background: #ffffff;
    width: 100vw;
    min-height: 100vh;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .row {
    --mdb-gutter-x: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.37rem;
  }

  .p-smaller {
    size: 0.8rem !important;
  }
  
  .navbar {
    height: 60px;
  }
}


